//Autocomplete Moteur
function Autocomplete(url, idSearch, idSearchLabel) {

    let referenceVal = Slugify(document.getElementById(idSearchLabel).value);
    let inputLabel = document.getElementById(idSearchLabel);

    //Ajoute la saisie de l'utilisateur sans selection de l'autocomplete
    inputLabel.addEventListener("keyup", function () {
        setTimeout(function () {
            document.getElementById(idSearch).value = inputLabel.value;
        }, 0);
    });

    //Selectionner l'autocompplete à partir de 2 caractères saisis
    if (referenceVal.length > 2) {
        let http = new XMLHttpRequest();
        http.open("post", url.replace('--search--', referenceVal, true));
        http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        http.onload = function () {
            if (http.readyState == 4 && http.status == 200) {
                let results = JSON.parse(http.responseText);
                if (results.length != 0) {
                    //Afficher les resultats
                    displayResults(results, idSearch, idSearchLabel);
                }
            }
        };

        http.send();
    }
}

//Affichage des resultats (div)
function displayResults(results, idSearch, idSearchLabel) {
    const formGroup = document.getElementById(idSearch).parentElement;
    let div = formGroup.querySelector(`.autocompleteResults`);

    //Créer un div si elle n'existe pas
    if (!div) {
        div = document.createElement('div');
        div.classList.add('autocompleteResults');
        document.body.appendChild(div);

        insertAfter(document.getElementById(idSearch), div)
    } else {
        div.classList.remove('hidden');
    }

    // Supprimez les anciens résultats
    div.innerHTML = '';

    let ul = document.createElement("ul")

    div.appendChild(ul);

    //Ajoute les résultats
    for (let result of results) {
        let li = document.createElement("li");
        ul.appendChild(li);

        let p = document.createElement("p");

        if (result.PostalCode !== null) {
            p.innerHTML = result.Label + " " + result.PostalCode;
        } else {
            p.innerHTML = result.Label;
        }

        if (result.PostalCode !== null) {
            p.setAttribute("data-label", result.Label + " " + result.PostalCode);
        } else {
            p.setAttribute("data-label", result.Label);
        }
        p.setAttribute("data-slug", result.Slug);
        p.setAttribute("data-postal-code", result.PostalCode || "");

        li.appendChild(p);

        p.addEventListener("click", function () {
            document.getElementById(idSearchLabel).value = this.getAttribute("data-label");

            let slug = this.getAttribute("data-slug");
            let postalCode = this.getAttribute("data-postal-code");
            let value = postalCode ? slug + "-" + postalCode : slug;

            document.getElementById(idSearch).value = value;
            div.classList.add('hidden');
        });
    }
}

//Fonction de formatage d'un slug
function Slugify(str) {
    str = str.replace(/^\s+|\s+$/g, '');

    str = str.toLowerCase();

    let from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    let to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');

    return str;
}

//Insert la div autocomplete après le moteur
function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}